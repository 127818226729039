@import "~admin-lte/build/scss/mixins/animations";
@import "~admin-lte/build/scss/mixins/scrollbar";
@import "~admin-lte/build/scss/mixins/cards";
@import "~admin-lte/build/scss/mixins/sidebar";
@import "~admin-lte/build/scss/mixins/navbar";
@import "~admin-lte/build/scss/mixins/accent";
@import "~admin-lte/build/scss/mixins/custom-forms";
/**
 * https://github.com/ColorlibHQ/AdminLTE/issues/4255
 */
// @import "~admin-lte/build/scss/mixins/backgrounds";
@import "mixins/backgrounds";
@import "~admin-lte/build/scss/mixins/dark-mode";
@import "~admin-lte/build/scss/mixins/direct-chat";
@import "~admin-lte/build/scss/mixins/toasts";
@import "~admin-lte/build/scss/mixins/touch-support";
@import "~admin-lte/build/scss/mixins/miscellaneous";
