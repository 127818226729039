@import "styles/abstracts/variables";

// Bootstrap
// ---------------------------------------------------
@import "~bootstrap/scss/functions";
@import "~admin-lte/build/scss/bootstrap-variables";
@import "~bootstrap/scss/bootstrap";

// Variables and Mixins
// ---------------------------------------------------
@import "~admin-lte/build/scss/variables";
@import "~admin-lte/build/scss/variables-alt";
@import "adminlte/mixins";

@import "styles/fonts";

@import "~admin-lte/build/scss/parts/core";
@import "~admin-lte/build/scss/parts/components";
@import "~admin-lte/build/scss/parts/extra-components";
@import "~admin-lte/build/scss/parts/pages";
@import "~admin-lte/build/scss/parts/plugins";
@import "~admin-lte/build/scss/parts/miscellaneous";
@import "jquery-simplecolorpicker/jquery.simplecolorpicker.css";
@import "bootstrap4-toggle/css/bootstrap4-toggle.min.css";
@import "daterangepicker/daterangepicker.css";